import React, { Component } from "react";
import Theme from "../Theme.js";
import { Button, PawIndicator } from "..";
import Active from "../../assets/paw-active.svg";
import Inactive from "../../assets/active_green.svg";

import Warning from "../../assets/paw_warning.svg";
import Vaccinated from "../../assets/active_green.svg";
import Expired from "../../assets/paw-expired.svg";
export default class MembershipInfo extends Component {
  handleActiveStatus = (confirmed, subActive) => {
    let status = {
      text: "",
      icon: null
    };
    if (confirmed && subActive) {
      status.text = "Active";
      status.icon = Active;
    } else if (!confirmed) {
      status.text = "Not Confirmed";
      status.icon = Inactive;
    } else if (!subActive) {
      status.text = "Inactive";
      status.icon = Inactive;
    }
    return status;
  };
  handleVaccineExpiredStatus = (confirmed, subActive, vaccineStatus) => {
    let status = {
      text: "",
      icon: null
    };
    if (vaccineStatus === "expiring_soon") {
      status.text = vaccineStatus;
      status.icon = Warning;
    } else if (vaccineStatus === "expired") {
      status.text = vaccineStatus;
      status.icon = Expired;
    } else if (vaccineStatus === "vaccinated") {
      status.text = vaccineStatus;
      status.icon = Vaccinated;
    }

    return status;
  };
  render() {
    const {
      onClick,
      width,
      shrink,
      subscription,
      users,
      package: memberPackage // Rename to avoid reserved word
    } = this.props,
      status = subscription ? subscription.status : "Inactive",
      cardLast4 = users?.cardLast4,
      styles = {
        membershipText: {
          display: "flex",
          flexDirection: "row",
          // height: 30,
          marginTop: "auto",
          marginBottom: "auto",
          color: Theme.DARK_TEXT_COLOR,
          fontSize: Theme.getNormalFont(width),
          borderRight: "1px solid #D0D2D5",
          width: "40%",
          justifyContent: "center",
          textAlign: "center"
        },
        membershipDescription: {
          display: "flex",
          flexDirection: "row",
          // height: 30,
          //paddingTop: 16,
          marginTop: "auto",
          marginBottom: "auto",
          color: Theme.DARK_TEXT_COLOR,
          fontSize: Theme.getNormalFont(width),
          borderRight: "1px solid #D0D2D5",
          width: "100%",
          justifyContent: "center",
          textAlign: "center"
        },
        membershipCard: {
          display: "flex",
          flexDirection: "row",
          // height: 30,
          //paddingTop: 16,
          marginTop: "auto",
          marginBottom: "auto",
          color: Theme.DARK_TEXT_COLOR,
          fontSize: Theme.getNormalFont(width),
          borderRight: "1px solid #D0D2D5",
          width: "100%",
          justifyContent: "center",
          textAlign: "center"
        },
        mobileMembership: {
          color: Theme.DARK_TEXT_COLOR,
          marginLeft: "5%",
          fontSize: Theme.getNormalFont(width)
        }
      };
    let { dogsStatus } = this.props;
    const renderActive = () => {
      let confirmed = users.confirmed;
      let subscriptionActive = dogsStatus.subscriptionActive;
      let vaccineStatus = dogsStatus.vaccineStatus;

      // console.log("Dog------------------------->", users);
      return (
        <div
          style={{
            color: Theme.DARK_TEXT_COLOR,
            fontSize: Theme.getNormalFont(width),
            marginTop: "auto",
            marginBottom: "auto",
            width: "40%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {  this.handleActiveStatus(confirmed, subscriptionActive)
            .text}
          <img
            alt={"activity"}
            style={{ marginLeft: 5 }}
            src={
              this.handleVaccineExpiredStatus(
                confirmed,
                subscriptionActive,
                vaccineStatus
              ).icon
            }
          />
          {/* <PawIndicator
            active={status === "Active"}
            style={{ marginLeft: 5 }}
          /> */}
        </div>
      )
    }

    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "1%",
          display: "flex",
          flexDirection: shrink ? "column" : "row",
          justifyContent: "space-between",
          boxShadow: "1px 1px 5px grey",
          borderRadius: 5
        }}
      >
        <Button
          type="button"
          onClick={onClick}
          text={memberPackage?.name || 'No Package Selected'}
          width={
            shrink
              ? Theme.getButtonWidth(width)
              : Theme.getButtonFullWidth(width)
          }
          style={{
            fontSize: Theme.getNormalFont(width),
            padding: "2%"
          }}
        />
        <div style={shrink ? styles.mobileMembership : styles.membershipText}>
          {memberPackage?.amountString || 'N/A'}
        </div>
        <div style={shrink ? styles.mobileMembership : styles.membershipCard}>
          {(cardLast4 === null || cardLast4 === "") ? "Not available" : `XXXX-XXXX-XXXX-${cardLast4}`}
        </div>
        <div
          style={{
            ...styles.membershipDescription,
            borderRight: "none"
          }}
        >
          {memberPackage?.description?.[0] || 'No description available'}
        </div>
        {renderActive()}
      </div>
    );
  }
}
