import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Theme from "../Theme.js";
import Gear from "../../assets/gear.svg";
import cookie from "js-cookie";
import { connect } from "react-redux";
import { updateUser } from "../../store/redux/actions";

class ProfileButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSettings: false
    };
  }

  getHeight(width) {
    if (width > 450) return 80;
    else if (width > 400) return 60;
    return 50;
  }
  getWidth(width) {
    if (width > 450) {
      return 130;
    } else if (width > 400) {
      return 80;
    }
    return 50;
  }
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.setState({ openSettings: false });
        }
      } catch (error) { }
    }
  };
  render() {
    const { picture, width, containerStyle, location, global } = this.props;
    const { openSettings } = this.state;
    // const width = obj.dimensions.width;

    // Debug logging for profile picture URL
    console.log('ProfileButton Debug:', {
      pictureUrl: picture,
      userId: global?.currentUser?.id,
      timestamp: new Date().toISOString()
    });

    const shrink = width < 700;
    return (
      <div style={{
        display: shrink ? "block" : "",
        width: shrink ? "fit-content" : "",
        position: shrink ? "absolute" : "",
        top: shrink ? "12px" : "",
        right: shrink ? "5px" : "",
        zIndex: 99,
        margin: (global.currentUser.type === "Manager"
          || global.currentUser.type === "Admin")
          ?
          (shrink ? "" : "0 0 0 0 ")
          :
          global.currentUser.type === "Employee" ?
            (shrink ? "" : "0 0 0 auto ")
            :
            (shrink ? "" : "0 0 0 auto ")
        ,
      }}>
        <button
          onClick={() => {
            this.props.history.push("/staff/member");
          }}
          disabled
          style={{
            height: 60,
            width: 60,
            outline: "none",
            backgroundColor: "white",
            borderRadius: 40
          }}
        >
          <img
            alt=""
            style={{
              marginLeft: -8,
              marginTop: -2,
              width: 60,
              height: 60,
              borderRadius: 40
            }}
            src={picture}
          />
        </button>
        <button
          style={{
            position: "absolute",
            background: `url(${Gear})`,
            marginTop: 5,
            cursor: "pointer",
            outline: "none",
            border: "none",
            marginLeft: -15,
            height: 18,
            width: 18
          }}
          onClick={() => {
            this.setState({ openSettings: !openSettings });
          }}
        />
        <div
          ref={node => (this.node = node)}
          style={
            openSettings
              ? {
                position: "absolute",
                marginTop: -30,
                borderRadius: 5,
                marginLeft: -80,
                display: "flex",
                flexDirection: "column",
                padding: "1%",
                boxShadow: "1px 1px 10px black",
                backgroundColor: "white"
              }
              : { display: "none" }
          }
        >
          <div
            style={{
              borderBottom: "2px solid",
              borderColor: Theme.ALT_BUTTON_COLOR,
              paddingBottom: "10%",
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.HEADER_FONT,
              color: Theme.DARK_TEXT_COLOR
            }}
          >
            Settings
          </div>
          {location.pathname != "/profile" &&
            <button
              onClick={() => this.props.history.push("/profile")}
              style={{
                backgroundColor: "white",
                border: "none",
                outline: "none",
                marginTop: "5%",
                cursor: "pointer",
                fontSize: Theme.getSmallFont(width),
                textAlign: "left"
              }}
            >
              Personal Settings
            </button>
          }
          <button
            style={{
              border: "none",
              cursor: "pointer",
              marginTop: "5%",
              outline: "none",
              fontSize: Theme.getSmallFont(width),
              textAlign: "left"
            }}
            onClick={() => {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }}
          >
            Logout
          </button>
        </div>
        {/* Add Park Name in Employee Dashboard */}

        { global.currentUser.type === "Employee" &&
          <span
            style={{
              display: "inline-block",
              float: "right",
              marginLeft: "10px",
              lineHeight: "57px",
              // fontSize: "25px",
              fontSize: shrink ? "15px" : "25px",
              fontFamily: "LatoBold",
            }}
          >
            {global.currentUser.park.name}
          </span>
        }
        { global.currentUser.type === "Manager" &&
          <span
            style={{
              display: "inline-block",
              float: "right",
              marginLeft: "10px",
              lineHeight: "57px",
              fontSize: shrink ? "18px" : "25px",
              fontFamily: "LatoBold",
            }}
          >
            {global.currentUser.park.name}
          </span>
        }
        { global.currentUser.type === "Admin" &&
          <span
            style={{
              display: "inline-block",
              float: "right",
              marginLeft: "10px",
              lineHeight: "57px",
              fontSize: shrink ? "18px" : "25px",
              fontFamily: "LatoBold",
            }}
          >
            {global.currentUser.park.name}
          </span>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileButton));
