import gql from "graphql-tag";

export const USER_DETAILS = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      id
      address
      park {
        id
        name
        isAcceptedParkRules
      }
      profilePictureCdn
      profilePicture {
        name
        url
      }
      pin
      stripeCardToken
      address2
      firstName
      lastName
      authorizedUser
      city
      state
      phoneNumber
      acceptsSms
      acceptedTerms
      completed
      step
      type
      email
      isTrainee
      lastCheckInTime
      signUpDate
      confirmed
      positions
      chargeOnDate
      cardLast4
      legacyPlan
      memberTags {
        id
        title
      }
      notes {
        content
        id
        tags
        author {
          firstName
          lastName
        }
        user {
          firstName
          lastName
          id
        }
      }
      zip

      dogs {
        id
        color
        profilePictureCdn
        profilePicture {
          name
          url
        }
        strikeCount
        isBanned
        breed
        pausedSubscription
        bannedMessage
        name
        signUpDate
        age
        birthdate
        sex
        rabies
        renewNeeded
        separateLeptospirosis
        leptospirosis
        bordetella
        subscriptionActive
        vaccineStatus
        isNeutered
        vaccinations {
          expiresOn
          type
          pictures {
            url
          }
        }
        package {
          id
          name
          amountString
          description
        }
        packageName
        subscription {
          status
        }
        dhlpp
      }
      email
      zip
      profilePictureCdn
      profilePicture {
        name
        url
      }
      deactivated
      acceptedRules
    }
  }
`;

export const DOG_DETAILS = gql`
  query DogDetail($id: ID!) {
    dogDetail(id: $id) {
      id
      color
      profilePictureCdn
      profilePicture {
        url
      }
      name
      signUpDate
      age
      birthdate
      sex
      chargeOnDate
      packageName
      package {
        id
        name
        amountString
        description
      }
      subscription {
        status
      }
      rabies
      separateLeptospirosis
      leptospirosis
      bordetella
      vaccinations {
        expiresOn
        type
        pictures {
          url
        }
      }
      dhlpp
    }
  }
`;

export const USER_DOG_DETAILS = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      address
      park {
        id
        name
      }

      dogs {
        id
        color
        profilePictureCdn
        profilePicture {
          url
        }

        packageName
        package {
          id
          name
          amountString
          description
        }
        subscription {
          status
        }
        dhlpp
      }
      email
      zip
      profilePictureCdn
      profilePicture {
        url
      }
      deactivated
      acceptedRules
    }
  }
`;

export const ACTIVITY_FEED = gql`
  query FetchActivityList(
    $parkId: ID
    $page: String!
    $perPage: String!
    $type: Activity!
    $start: String!
    $end: String!
    $requestPage: String!
  ) {
    fetchActivityList(
      parkId: $parkId
      type: $type
      start: $start
      end: $end
      page: $page
      perPage: $perPage
      requestPage: $requestPage
    ) {
      count
      totalPages
      events {
        checkedInApp
        isHomeParkCheckin
        checkinStatus
        checkinMessage

        checkedInDog {
          id
          name
          profilePictureCdn
          profilePicture {
            url
          }
          vaccineStatus
        }
        user {
          firstName
          lastName
          type
          id
          profilePictureCdn
          profilePicture {
            url
          }
        }
        timestamp
      }
    }
  }
`;

export const ACTIVITY_FEED_TIME_SERIES = gql`
  query activity(
    $parkId: ID
    $type: Activity!
    $start: String!
    $end: String!
  ) {
    fetchActivityTimeSeries(
      parkId: $parkId
      type: $type
      start: $start
      end: $end
    )
  }
`;

export const PACKAGES = gql`
  query Packages($showHidden: Boolean, $dogId: ID) {
    packages(showHidden: $showHidden, dogId: $dogId) {
      amountString
      description
      id
      name
      recurring
      hidden
    }
  }
`;

export const FETCH_EVENT_CREATOR = gql`
  query fetchEventCreater {
    fetchEventCreater
  }
`;

export const FETCH_EVENT_TYPE = gql`
  query fetchEventType {
    fetchEventType
  }
`;

export const FETCH_EVENT_LISTS = gql`
  query fetchEventLists(
    $page: Int
    $userId: ID
    $byType: String
    $createdBy: String
    $date: String
    $sortAttr: String
    $sort: String
    $parkId: ID
  ) {
    fetchEventLists(
      byType: $byType
      createdBy: $createdBy
      date: $date
      sortAttr: $sortAttr
      sort: $sort
      userId: $userId
      parkId: $parkId
    ) {
      totalCount
      edges(page: $page, per: 20) {
        id
        eventName
        eventObject
        currentUser
        createdAt
        message
        park {
          id
          name
        }
        user {
          id
          firstName
          email
        }
      }
    }
  }
`;

export const FETCH_EMPLOYEE_EVENT_LISTS = gql`
  query employeeEvent(
    $page: Int
    $employeeId: ID
    $byType: String
    $memberId: ID
    $date: String
    $sortAttr: String
    $sort: String
  ) {
    employeeEvent(
      byType: $byType
      memberId: $memberId
      date: $date
      sortAttr: $sortAttr
      sort: $sort
      employeeId: $employeeId
    ) {
      totalCount
      edges(page: $page, per: 20) {
        id
        eventName
        eventObject
        currentUser
        createdAt
        message
        user {
          id
          firstName
          email
        }
        member {
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_FEED = gql`
  query FetchUsers(
    $parkId: ID
    $type: UserEnum
    $search: String
    $page: Int
    $per: Int
    $keywords: [String!]
  ) {
    fetchUsers(
      parkId: $parkId
      type: $type
      search: $search
      keywords: $keywords
    ) {
      totalCount
      edges(per: $per, page: $page) {
        id
        lastName
        firstName
        firstName
        signUpDate

        notes {
          content
          id
          tags
          author {
            firstName
            lastName
          }
          user {
            firstName
            lastName
            id
          }
        }
        park {
          id
        }
        dogs {
          id
          name
          signUpDate
          isBanned
          packageName
          subscription {
            status
          }
          pausedSubscription
          subscriptionActive
          vaccineStatus
          rabies
          renewNeeded
          profilePictureCdn
          profilePicture {
            url
          }

          package {
            id
            name
            amountString
            description
          }
          isNeutered
        }
        profilePictureCdn
        confirmed
        status
        acceptedRules
      }
    }
  }
`;

export const USER_NOTES = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      firstName
      lastName
      notes {
        content
        id
        tags
        user {
          firstName
          lastName
          id
        }
      }
      zip
    }
  }
`;

export const USER_PAYMENT = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      cardLast4
      stripeCardToken
      id
      dogs {
        id
        color
        profilePictureCdn
        profilePicture {
          url
        }

        name
        signUpDate
        age
        birthdate
        sex
        rabies
        separateLeptospirosis
        leptospirosis
        bordetella
        subscriptionActive
        vaccineStatus
        isNeutered
        vaccinations {
          expiresOn
          type
          pictures {
            url
          }
        }
        package {
          id
          name
          amountString
          description
        }
        subscription {
          status
        }
        dhlpp
      }
    }
  }
`;

export const USER_FEED_NEW = gql`
  query FetchUsers(
    $parkId: ID
    $type: UserEnum
    $search: String
    $page: Int
    $per: Int
  ) {
    fetchUsers(parkId: $parkId, type: $type, search: $search) {
      totalCount
      edges(per: $per, page: $page) {
        id
        dogs {
          id
          name
          signUpDate
          package {
            id
            name
            amountString
          }
        }
      }
    }
  }
`;

export const FETCH_PARKS = gql`
  query parks {
    fetchParks {
      address
      address2
      city
      generalManager
      generalManagerPhoneNumber
      generalManagerEmail
      memberCount
      phoneNumber
      name
      id
      generalManagerProfilePicture {
        url
      }
      hours {
        daysOfWeek
        hours
      }
      state
    }
  }
`;

export const FETCH_TIMEZONE = gql`
  query fetchTimezones {
    fetchTimezones
  }
`;
export const FETCH_PARK_STATS = gql`
  query parks(
    $parkId: ID
    $type: UserEnum
    $per: Int
    $page: Int
    $start: String!
    $end: String!
  ) {
    park: fetchParks(id: $parkId) {
      address
      address2
      city
      generalManager
      generalManagerPhoneNumber
      generalManagerEmail
      generalManagerProfilePicture {
        url
      }
      timezone
      timezoneFullname
      memberCount
      phoneNumber
      name
      id
      hours {
        daysOfWeek
        hours
      }
      state
      selfCheckinEnabled
    }
    staff: fetchUsers(parkId: $parkId, type: $type) {
      totalCount
      edges(per: $per, page: $page) {
        email
        id
        firstName
        hireDate
        lastName
        phoneNumber
        positions
        deactivated
        status
        type
        notes {
          content
          tags
        }
      }
    }
    signUp: fetchActivityList(
      parkId: $parkId
      type: sign_up
      start: $start
      end: $end
    ) {
      count
    }
    checkIn: fetchActivityList(
      parkId: $parkId
      type: check_in
      start: $start
      end: $end
    ) {
      count
    }
  }
`;

export const PARK_NAMES = gql`
  query RestrictedFetchParks {
    restrictedFetchParks {
      id
      name
    }
  }
`;

export const FETCH_RANGERS = gql`
  query barkRanger(
    $type: UserEnum
    $page: Int
    $per: Int
    $search: String
    $employeeType: String
  ) {
    fetchUsers(type: $type, search: $search, employeeType: $employeeType) {
      totalCount
      edges(per: $per, page: $page) {
        email
        id
        firstName
        hireDate
        lastName
        positions
        deactivated
        isTrainee
        park {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_MEMBERS = gql`
  query members($type: UserEnum, $page: Int, $per: Int, $search: String) {
    fetchUsers(type: $type, search: $search) {
      totalCount
      edges(per: $per, page: $page) {
        id
        lastName
        firstName
        signUpDate
        package {
          id
          name
          amountString
        }
        deactivated
        park {
          id
          name
        }
        completed
        confirmed
        dogs {
          id
          name
          signUpDate
          age
          sex
          package {
            id
            name
            amountString
            description
          }
          subscription {
            status
          }
          rabies
          separateLeptospirosis
          leptospirosis
          bordetella
          vaccinationPictures {
            url
          }
          signUpDate
          package {
            id
            name
            amountString
          }
          deactivated
          park {
            id
            name
          }
          subscriptionActive
          completed
          confirmed
          legacyPlan
        }
      }
    }
  }
`;

export const FETCH_PROFILE_PIC = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      profilePictureCdn
      profilePicture {
        url
      }
    }
  }
`;

export const FETCH_MEMBERSHIP = gql`
  query UserDetail($id: ID!) {
    userDetail(id: $id) {
      chargeOnDate
      confirmed
      cardLast4

      dogs {
        id
        name
        package {
          id
          name
          amountString
          recurring
          description
        }
        subscriptionActive
        vaccineStatus
      }
      subscription {
        status
      }
    }
  }
`;

export const FETCH_COUPON_TYPES = gql`
  query CouponTypes {
    couponTypes {
      id
      name
      description
    }
  }
`;

export const FETCH_PARK_NAME = gql`
  query parks {
    fetchParks {
      name
      id
    }
  }
`;

export const FETCH_TAG_LIST = gql`
  query fetchTags {
    fetchTags {
      id
      description
      title
    }
  }
`;
export const FETCH_MEMBERS_LIST = gql`
  query fetchMembers($keyword: [String!]) {
    fetchMembers(keyword: $keyword) {
      id
      firstName
      lastName
    }
  }
`;
export const FETCH_MEMBERS_LIST_BY_TAG = gql`
  query fetchTagMembers($tagId: ID!) {
    fetchTagMembers(tagId: $tagId) {
      email
      firstName
      lastName
      memberTags {
        id
        title
      }
      profilePictureCdn
      profilePicture {
        url
      }
      id
    }
  }
`;

export const FETCH_EVENT_LIST_WITH_TAG = gql`
  query fetchEvent($keyword: String!, $sortAttr: String!, $sort: String!) {
    fetchEvent(keyword: $keyword, sortAttr: $sortAttr, sort: $sort) {
      id
      eventName
      tagName
      eventDate
      startTime
      endTime
      createdBy
      tageId
      eventType

      createdTimezone
      allDayStatus
      endDate
      description
      park {
        id
        name
      }
      eventTags {
        id
        tagName
        tageId
      }
    }
  }
`;
export const FETCH_EVENT_LIST_WITH_PARK = gql`
  query fetchParkEvents($parkId: ID!) {
    fetchParkEvents(parkId: $parkId) {
      id
      eventName
      tagName
      eventDate
      startTime
      endTime
      createdBy
      tageId
      eventType
      endDate
      description
      park {
        id
        name
      }
      eventTags {
        id
        tagName
        tageId
      }
    }
  }
`;

export const FETCH_CALENDAR_EVENT_LIST_WITH_PARK = gql`
  query fetchCalendarEvents($parkId: ID!, $page: Int!) {
    fetchCalendarEvents(parkId: $parkId, page: $page) {
      date
      eve {
        id
        createdTimezone
        allDayStatus
        eventType
        eventDate
        eventName
        startTime
        endTime
        endDate
        description
        createdEmail
        createdBy
        createdAt
        eventTags {
          id
          tagName
        }
        tagName
        tageId
        park {
          id
          name
        }
      }
    }
  }
`;
export const FETCH_EVENT_PARK_LOG = gql`
  query parkEvent(
    $page: Int
    $parkId: ID
    $sortAttr: String
    $sort: String
    $byType: String
    $date: String
    $createdBy: String
    $per: Int
  ) {
    parkEvent(
      sortAttr: $sortAttr
      sort: $sort
      parkId: $parkId
      byType: $byType
      date: $date
      createdBy: $createdBy
    ) {
      totalCount
      edges(page: $page, per: $per) {
        id
        eventName
        createdAt
        currentUser
        message
        eventObject
        dog {
          packageName
          name
        }
        park {
          name
        }
        member {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const CHECK_IN_PHONE_USER = gql`
  query getUser($contact: String!) {
    getUser(contact: $contact) {
      address
      stripeCardToken
      firstName
      lastName
      phoneNumber
      completed
      confirmed
      positions
      chargeOnDate
      cardLast4
      legacyPlan
      memberTags {
        id
        title
      }

      zip

      dogs {
        id
        color
        profilePictureCdn
        profilePicture {
          url
        }
        strikeCount
        isBanned
        breed
        pausedSubscription
        bannedMessage
        name
        signUpDate
        age
        birthdate
        sex
        rabies
        renewNeeded
        separateLeptospirosis
        leptospirosis
        bordetella
        subscriptionActive
        vaccineStatus
        isNeutered
        vaccinations {
          expiresOn
          type
          pictures {
            url
          }
        }
        package {
          id
          name
          amountString
          description
        }
        packageName
        subscription {
          status
        }
        dhlpp
      }
      email
      zip
      profilePictureCdn
      profilePicture {
        url
      }
      deactivated
      acceptedRules
    }
  }
`;

export const PARK_RULE_CHECK = gql`
  query fetchUserParkRuleStatus($userId: ID!, $parkId: ID!) {
    fetchUserParkRuleStatus(userId: $userId, parkId: $parkId)
  }
`;
