import React, { Component } from "react";
import Theme from "../Theme.js";
import { DirectUpload } from "@rails/activestorage";
import { PulseLoader } from "react-spinners";

export default class PictureUpload extends Component {
  state = {
    tempPhoto: null,
    loading: false,
    nameDog: ""
  };

  getHeight(width) {
    if (width > 450) return 140;
    if (width > 400) return 110;
    if (width > 300) return 80;
    return 90;
  }

  getWidth(width) {
    if (width > 450) return 140;
    if (width > 400) return 100;
    if (width > 300) return 60;
    else return 60;
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== this.state.nameDog) {
      this.setState({ nameDog: this.props.name, tempPhoto: null });
    }
    
    // Reset tempPhoto if picture prop changes and we're not loading
    if (prevProps.picture !== this.props.picture && !this.state.loading) {
      this.setState({ tempPhoto: null });
    }
  }

  getDisplayPicture(picture) {
    if (!picture) return null;
    
    // If it's a base64 data URL, use it as is
    if (picture.startsWith('data:image')) {
      return picture;
    }
    
    // If it's a CDN URL (starts with https), use it as is
    if (picture.startsWith('http')) {
      return picture;
    }
    
    // If it's an ActiveStorage URL (starts with /rails), prepend the API endpoint
    if (picture.startsWith('/rails')) {
      return process.env.REACT_APP_API_ENDPOINT + picture;
    }
    
    // If it's an object with a url property
    if (picture.url) {
      return picture.url.startsWith('http') 
        ? picture.url 
        : process.env.REACT_APP_API_ENDPOINT + picture.url;
    }
    
    return picture;
  }

  uploadFile = async (event) => {
    try {
      // Persist the event to prevent React's event pooling from nullifying it
      event.persist();
      
      const file = event.target.files?.[0];
      if (!file) {
        console.log('Upload Debug - No file selected');
        return;
      }

      this.setState({ loading: true });

      // Generate preview immediately
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Upload Debug - Preview generated:', {
          previewLength: reader.result.length,
          isBase64: reader.result.startsWith('data:image')
        });
        this.setState({ tempPhoto: reader.result });
      };
      reader.readAsDataURL(file);

      // Get API endpoint
      const apiEndpoint = window.REACT_APP_API_ENDPOINT || process.env.REACT_APP_API_ENDPOINT || '';
      const url = apiEndpoint + "/rails/active_storage/direct_uploads";

      console.log('Upload Debug - Starting upload:', {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        apiEndpoint,
        uploadUrl: url,
        timestamp: new Date().toISOString()
      });

      // Use DirectUpload like the working components
      const upload = new DirectUpload(file, url);
      
      await new Promise((resolve, reject) => {
        upload.create((error, blob) => {
          if (error) {
            console.error('Upload Debug - Upload error:', {
              errorType: error.constructor.name,
              errorMessage: error.message,
              errorStack: error.stack,
              timestamp: new Date().toISOString()
            });
            reject(error);
            return;
          }

          console.log('Upload Debug - Upload successful:', {
            blobId: blob.id,
            blobSignedId: blob.signed_id,
            timestamp: new Date().toISOString()
          });

          // Call onUpload with both the signed_id and preview
          if (this.props.onUpload) {
            this.props.onUpload(blob.signed_id, this.state.tempPhoto);
          }
          resolve(blob);
        });
      });

    } catch (error) {
      console.log('Upload Debug - Critical error in uploadFile:', {
        errorType: error.constructor.name,
        errorMessage: error.message,
        errorStack: error.stack,
        timestamp: new Date().toISOString()
      });
      
      // Clear temporary photo on error
      this.setState({ tempPhoto: null });
      
      // Show user-friendly error
      alert('Failed to upload image. Please try again.');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      picture,
      name,
      width,
      containerStyle,
      disableForMembers,
      verticalCenter,
      notSelected
    } = this.props;
    const { tempPhoto, loading } = this.state;
    const shrink = width < 725;

    // Handle the picture URL
    const displayPicture = this.getDisplayPicture(tempPhoto || picture);

    // Debug logging for image URLs
    console.log('PictureUpload Debug:', {
      providedPicture: picture,
      tempPhoto: tempPhoto,
      finalDisplayPicture: displayPicture,
      isBase64: (tempPhoto || picture || '').startsWith('data:image'),
      isCdn: typeof picture === 'string' && picture.startsWith('http'),
      isActiveStorage: typeof picture === 'string' && picture.startsWith('/rails'),
      timestamp: new Date().toISOString()
    });

    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: this.getHeight(width),
            width: this.getWidth(width),
            marginBottom: 15,
            border: "3px solid transparent",
            ...containerStyle
          }}
        >
          <PulseLoader
            style={{ margin: "auto" }}
            size={10}
            color={"#fff"}
            loading={loading}
          />
        </div>
      );
    } else
      return (
        <div style={containerStyle}>
          <input
            id={name}
            name={name}
            type="file"
            style={{
              width: 0.1,
              height: 0.1,
              opacity: 0,
              overflow: "hidden",
              position: "absolute",
              zIndex: -1
            }}
            onChange={event => {
              if (disableForMembers && !(this.props.picturePresent === null)) {
                alert("Please see an employee to update this image.");
              } else {
                this.setState({ loading: true });
                this.uploadFile(event);
              }
            }}
          />
          <label
            htmlFor={name}
            style={{
              display: shrink ? "block" : "flex",
              flexDirection: "column",
              backgroundColor: "white",
              marginBottom: `${verticalCenter ? "auto" : "15px"}`,
              marginTop: `${verticalCenter ? "auto" : ""}`,
              marginLeft: shrink ? "auto" : "",
              marginRight: shrink ? "auto" : "",
              border: "3px solid",
              borderRadius: 10,
              cursor: "pointer",
              height: this.getHeight(width),
              width: this.getWidth(width),
              minHeight: 140,
              minWidth: 140,
              borderColor: notSelected
                ? Theme.LIGHT_TEXT_COLOR
                : Theme.LINK_FONT_COLOR
            }}
          >
            {displayPicture ? (
              <img
                alt=""
                style={{
                  borderRadius: 7,
                  height: this.getHeight(width),
                  width: this.getWidth(width),
                  minHeight: 140,
                  minWidth: 140
                }}
                src={displayPicture}
              />
            ) : (
                <div
                  style={{
                    color: Theme.LIGHT_TEXT_COLOR,
                    fontSize: Theme.getSmallFont(width),
                    fontFamily: Theme.MAIN_FONT,
                    margin: "auto"
                  }}
                >
                  + Picture
                </div>
              )}
          </label>

          {this.props.bannedComponent}
         
        </div>
      );
  }
}
